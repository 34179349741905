import {FC, useRef} from "react";
import './style.scss';
import {useOnClickOutside} from "../../../../helpers";
import cx from "classnames";

interface ISelectOptions {
    className?:string,
    onChange:any
    dataWrapper:any
    activeFlag?:boolean
    activeKey?:string
    setFlagSort?:any,
    isLeft?:boolean
}

const SelectOptions:FC<ISelectOptions> = ({
    className,
    onChange,
    dataWrapper,
    activeFlag=false,
    activeKey,
    setFlagSort,
    isLeft
}) => {
    const selectRef = useRef<HTMLDivElement>(null);
    useOnClickOutside(() => {
        setFlagSort(false)
    }, [selectRef]);

    const contentClassName = cx('select-options',
        className,
        activeFlag ? 'active' : '',
        isLeft ? 'left' : ''
    );

    return(
        <div className={contentClassName} ref={selectRef}>
            {Object.keys(dataWrapper).map((key:string, index:number)=>(
                <button key={index} className={`select-options__btn ${activeKey === key ? 'active':''}`} onClick={()=>onChange(key)}>
                    {(dataWrapper as any)[`${key}`]}
                </button>
            ))}
        </div>
    )
}

export default SelectOptions;