export const fieldsReportRow = {
    'EventDate': 'Дата показа',
    'MediaSource': 'Источник',
    'MediaCampaign': 'Кампания',
    'MediaCreative': 'Креатив',
    'Domain': 'Домен',
    'SSP': 'SSP',
    'custommedia_': 'Макрос на показ',
    'Country': 'Страна',
    'City': 'Город',
    //параметры целевых действий
    'TargetEventDate': 'Дата целевого действия',
    "TargetYandexMetrikaID": 'Яндекс метрика ID',
    "TargetGoogleAnalyticsID": 'Google Analytics ID',
    'TargetEventDomain': 'Домен целевого действия',
    'TargetEventPath': 'Путь целевого действия',
    'TargetEventReferrer': 'Referrer целевого действия',
    // 'TargetEventUtmSource': 'UTM Source',
    // 'TargetEventUtmMedium': 'UTM Medium',
    // 'TargetEventUtmCampaign': 'UTM Кампания',
    // 'TargetEventUtmContent': 'UTM Контент',
    'customevent_': 'Кастомный параметр',
    'TargetEventUserId': 'ID пользователя',
    'TargetEventType': 'Тип целевого действия',
    'TargetEventCategory': 'Категория целевого действия',
    'TargetEventName': 'Имя целевого действия',
    'TargetEcomId': 'Ecommerce ID',
    'TargetEcomItemsId': 'ID товара',
    'TargetEcomItemsName': 'Название товара',
    'TargetEcomItemsBrand': 'Бренд товара',
    'TargetEcomItemsVariant': 'Разновидность товара',
    'TargetEcomItemsCategory1': 'Категория товара 1',
    'TargetEcomItemsCategory2': 'Категория товара 2',
    'TargetEcomItemsQuantity': 'Количество товаров',
    'TargetEcomItemsPrice': 'Цена товаров'
}
// EventDate=Дата показа;MediaSource=Источник;MediaCampaign=Кампания;MediaCreative=Креатив;Domain=Домен;SSP=SSP;custommedia_=Макрос на показ;Country=Страна;City=Город;TargetEventDate=Дата целевого действия;TargetEventDomain=Домен целевого действия;TargetEventPath=Путь целевого действия;TargetEventReferrer=Referrer целевого действия;TargetEventUtmSource=UTM Source;TargetEventUtmMedium=UTM Medium;TargetEventUtmCampaign=UTM Кампания;TargetEventUtmContent=UTM Контент;customevent_=Кастомный параметр;TargetEventUserId=ID пользователя;TargetEventType=Тип целевого действия;TargetEventCategory=Категория целевого действия;TargetEventName=Имя целевого действия;TargetEcomId=Ecommerce ID;TargetEcomItemsId=ID товара;TargetEcomItemsName=Название товара;TargetEcomItemsBrand=Бренд товара;TargetEcomItemsVariant=Разновидность товара;TargetEcomItemsCategory1=Категория товара1;TargetEcomItemsCategory2=Категория товара2;TargetEcomItemsQuantity=Количество товаров;TargetEcomItemsPrice=Цена товаров
// EventDate=;MediaSource=;MediaCampaign=;MediaCreative=;Domain=;SSP=;Event_param=;Country=;City=;TargetEventDate=;TargetEventDomain=;TargetEventPath=;TargetEventReferrer=;TargetEventUtmSource=;TargetEventUtmMedium=;TargetEventUtmCampaign=;TargetEventUtmContent=;customevent_=;TargetEventUserId=;TargetEventType=;TargetEventCategory=;TargetEventName=;TargetEcomId=;TargetEcomItemsId=;TargetEcomItemsName=;TargetEcomItemsBrand=;TargetEcomItemsVariant=;TargetEcomItemsCategory1=;TargetEcomItemsCategory2=;TargetEcomItemsQuantity=;TargetEcomItemsPrice=
export const fieldsReportRowConditions = {
    'EventDate': {
        'contain': 'Содержит',
        'not_contain': 'Не содержит',
        'equal': 'Равно',
        'not_equal': 'Не равно'
    },
    'MediaSource': {
        'contain': 'Содержит',
        'not_contain': 'Не содержит',
        'equal': 'Равно',
        'not_equal': 'Не равно'
    },
    'MediaCampaign': {
        'contain': 'Содержит',
        'not_contain': 'Не содержит',
        'equal': 'Равно',
        'not_equal': 'Не равно'
    },
    'MediaCreative': {
        'contain': 'Содержит',
        'not_contain': 'Не содержит',
        'equal': 'Равно',
        'not_equal': 'Не равно'
    },
    'Domain': {
        'contain': 'Содержит',
        'not_contain': 'Не содержит',
        'equal': 'Равно',
        'not_equal': 'Не равно'
    },
    'SSP': {
        'contain': 'Содержит',
        'not_contain': 'Не содержит',
        'equal': 'Равно',
        'not_equal': 'Не равно'
    },
    'custommedia_': {
        'contain': 'Содержит',
        'not_contain': 'Не содержит',
        'equal': 'Равно',
        'not_equal': 'Не равно'
    },
    'Country': {
        'contain': 'Содержит',
        'not_contain': 'Не содержит',
        'equal': 'Равно',
        'not_equal': 'Не равно'
    },
    'City': {
        'contain': 'Содержит',
        'not_contain': 'Не содержит',
        'equal': 'Равно',
        'not_equal': 'Не равно'
    },
    //параметры целевых действий
    'TargetEventDate': {
        'contain': 'Содержит',
        'not_contain': 'Не содержит',
        'equal': 'Равно',
        'not_equal': 'Не равно'
    },
    'TargetEventDomain': {
        'contain': 'Содержит',
        'not_contain': 'Не содержит',
        'equal': 'Равно',
        'not_equal': 'Не равно'
    },
    'TargetEventPath': {
        'contain': 'Содержит',
        'not_contain': 'Не содержит',
        'equal': 'Равно',
        'not_equal': 'Не равно'
    },
    'TargetEventReferrer': {
        'contain': 'Содержит',
        'not_contain': 'Не содержит',
        'equal': 'Равно',
        'not_equal': 'Не равно'
    },
    'TargetEventUtmSource': {
        'contain': 'Содержит',
        'not_contain': 'Не содержит',
        'equal': 'Равно',
        'not_equal': 'Не равно'
    },
    'TargetEventUtmMedium': {
        'contain': 'Содержит',
        'not_contain': 'Не содержит',
        'equal': 'Равно',
        'not_equal': 'Не равно'
    },
    'TargetEventUtmCampaign': {
        'contain': 'Содержит',
        'not_contain': 'Не содержит',
        'equal': 'Равно',
        'not_equal': 'Не равно'
    },
    'TargetEventUtmContent': {
        'contain': 'Содержит',
        'not_contain': 'Не содержит',
        'equal': 'Равно',
        'not_equal': 'Не равно'
    },
    'customevent_': {
        'contain': 'Содержит',
        'not_contain': 'Не содержит',
        'equal': 'Равно',
        'not_equal': 'Не равно',
    },
    'TargetEventUserId': {
        'contain': 'Содержит',
        'not_contain': 'Не содержит',
        'equal': 'Равно',
        'not_equal': 'Не равно'
    },
    'TargetEventType': {
        'contain': 'Содержит',
        'not_contain': 'Не содержит',
        'equal': 'Равно',
        'not_equal': 'Не равно'
    },
    'TargetEventCategory': {
        'contain': 'Содержит',
        'not_contain': 'Не содержит',
        'equal': 'Равно',
        'not_equal': 'Не равно'
    },
    'TargetEventName': {
        'contain': 'Содержит',
        'not_contain': 'Не содержит',
        'equal': 'Равно',
        'not_equal': 'Не равно'
    },
    'TargetEcomId': {
        'contain': 'Содержит',
        'not_contain': 'Не содержит',
        'equal': 'Равно',
        'not_equal': 'Не равно'
    },
    'TargetEcomItemsId': {
        'contain': 'Содержит',
        'not_contain': 'Не содержит',
        'equal': 'Равно',
        'not_equal': 'Не равно'
    },
    'TargetEcomItemsName': {
        'contain': 'Содержит',
        'not_contain': 'Не содержит',
        'equal': 'Равно',
        'not_equal': 'Не равно'
    },
    'TargetEcomItemsBrand': {
        'contain': 'Содержит',
        'not_contain': 'Не содержит',
        'equal': 'Равно',
        'not_equal': 'Не равно'
    },
    'TargetEcomItemsVariant': {
        'contain': 'Содержит',
        'not_contain': 'Не содержит',
        'equal': 'Равно',
        'not_equal': 'Не равно'
    },
    'TargetEcomItemsCategory1': {
        'contain': 'Содержит',
        'not_contain': 'Не содержит',
        'equal': 'Равно',
        'not_equal': 'Не равно'
    },
    'TargetEcomItemsCategory2': {
        'contain': 'Содержит',
        'not_contain': 'Не содержит',
        'equal': 'Равно',
        'not_equal': 'Не равно'
    },
    'TargetEcomItemsQuantity': {
        'contain': 'Содержит',
        'not_contain': 'Не содержит',
        'equal': 'Равно',
        'not_equal': 'Не равно'
    },
    'TargetEcomItemsPrice': {
        'contain': 'Содержит',
        'not_contain': 'Не содержит',
        'equal': 'Равно',
        'not_equal': 'Не равно'
    },
}
export const fieldsReportRowConditionValue = {
    'MediaSource': '',
    'MediaCampaign': '',
    'MediaCreative': '',
    'Domain': '',
    'SSP': '',
    'custommedia_': '',
    'Country': '',
    'City': '',
    //параметры целевых действий
    'TargetEventDomain': '',
    'TargetEventPath': '',
    'TargetEventReferrer': '',
    'TargetEventUtmSource': '',
    'TargetEventUtmMedium': '',
    'TargetEventUtmCampaign': '',
    'TargetEventUtmContent': '',
    'customevent_': '',
    'TargetEventUserId': '',
    'TargetEventType': '',
    'TargetEventCategory': '',
    'TargetEventName': '',
    'TargetEcomId': '',
    'TargetEcomItemsId': '',
    'TargetEcomItemsName': '',
    'TargetEcomItemsBrand': '',
    'TargetEcomItemsVariant': '',
    'TargetEcomItemsCategory1': '',
    'TargetEcomItemsCategory2': '',
    'TargetEcomItemsQuantity': '',
    'TargetEcomItemsPrice': '',
}

export const fieldsReportRowConditionValueTypes = {
    'MediaSource': 'string',
    'MediaCampaign': 'string',
    'MediaCreative': 'string',
    'Domain': 'string',
    'SSP': 'string',
    'custommedia_': 'string',
    'Country': 'string',
    'City': 'string',
    //параметры целевых действий
    'TargetEventDomain': 'string',
    'TargetEventPath': 'string',
    'TargetEventReferrer': 'string',
    'TargetEventUtmSource': 'string',
    'TargetEventUtmMedium': 'string',
    'TargetEventUtmCampaign': 'string',
    'TargetEventUtmContent': 'string',
    'customevent_': 'string',
    'TargetEventUserId': 'string',
    'TargetEventType': 'string',
    'TargetEventCategory': 'string',
    'TargetEventName': 'string',
    'TargetEcomId': 'string',
    'TargetEcomItemsId': 'string',
    'TargetEcomItemsName': 'string',
    'TargetEcomItemsBrand': 'string',
    'TargetEcomItemsVariant': 'string',
    'TargetEcomItemsCategory1': 'string',
    'TargetEcomItemsCategory2': 'string',
    'TargetEcomItemsQuantity': 'string',
    'TargetEcomItemsPrice': 'string',
}

export const metricsReportRow = {
    'eventitem_': 'Достижение цели',
    //метрики целевых значений
    'TargetEcomAmount': 'Сумма продаж',
    'TargetEcomQuantity': 'Товаров',
    // 'SessionCount': 'Кол-во сессий',
    'TargetEventValue': 'Ценность целевых действий',
    'TargetEventCount': 'Кол-во целевых действий',
    //Метрики медийные
    'impressions': 'Показы',
    'clicks': 'Клики',
    'reach': 'Охват',
    'frequency': 'Частота',
    'givt': 'GIVT',
    'behavior_fraud_impressions': 'Поведенческий фрод',
    'hidden_fraud_impressions': 'Скрытые показы',
    'visible_impressions': 'Видимые показы',
    'avg_visible_impressions_duration': 'Средняя продолжительность видимых показов',
    'median_visible_impressions_duration': 'Медиана продолжительности видимых показов',
    'part_of_screen': 'Часть экрана',
    'part_of_visible_screen': 'Видимая часть экрана',
    'visible_part': 'Видимая часть',
    'creative_weight': 'Ширина креатива',
    'creative_height': 'Высота креатива',
    'video_start': 'Старт видео',
    'video_start_on_mute': 'Старт видео с отключенным звуком',
    'video_q25': '25% видео',
    'video_q50': '50% видео',
    'video_q75': '75% видео',
    'video_complete': 'Досмотр видео',
    'video_skip': 'Пропуск видео',
    'video_close': 'Закрыто видео',
}

export const metricsReportRowConditions = {
    //метрики целевых значений
    'eventitem_': {
        'equal': 'Равно',
        'more': 'Больше',
        'less': 'Меньше',
    },
    'TargetEcomAmount': {
        'equal': 'Равно',
        'more': 'Больше',
        'less': 'Меньше',
    },
    'TargetEcomQuantity': {
        'equal': 'Равно',
        'more': 'Больше',
        'less': 'Меньше',
    },
    'SessionCount': {
        'equal': 'Равно',
        'more': 'Больше',
        'less': 'Меньше',
    },
    'TargetEventValue': {
        'equal': 'Равно',
        'more': 'Больше',
        'less': 'Меньше',
    },
    'TargetEventCount': {
        'equal': 'Равно',
        'more': 'Больше',
        'less': 'Меньше',
    },
    //Метрики медийные
    'impressions': {
        'equal': 'Равно',
        'more': 'Больше',
        'less': 'Меньше',
    },
    'clicks': {
        'equal': 'Равно',
        'more': 'Больше',
        'less': 'Меньше',
    },
    'reach': {
        'equal': 'Равно',
        'more': 'Больше',
        'less': 'Меньше',
    },
    'frequency': {
        'equal': 'Равно',
        'more': 'Больше',
        'less': 'Меньше',
    },
    'givt': {
        'equal': 'Равно',
        'more': 'Больше',
        'less': 'Меньше',
    },
    'behavior_fraud_impressions': {
        'equal': 'Равно',
        'more': 'Больше',
        'less': 'Меньше',
    },
    'hidden_fraud_impressions': {
        'equal': 'Равно',
        'more': 'Больше',
        'less': 'Меньше',
    },
    'visible_impressions': {
        'equal': 'Равно',
        'more': 'Больше',
        'less': 'Меньше',
    },
    'avg_visible_impressions_duration': {
        'equal': 'Равно',
        'more': 'Больше',
        'less': 'Меньше',
    },
    'median_visible_impressions_duration': {
        'equal': 'Равно',
        'more': 'Больше',
        'less': 'Меньше',
    },
    'part_of_screen': {
        'equal': 'Равно',
        'more': 'Больше',
        'less': 'Меньше',
    },
    'part_of_visible_screen': {
        'equal': 'Равно',
        'more': 'Больше',
        'less': 'Меньше',
    },
    'visible_part': {
        'equal': 'Равно',
        'more': 'Больше',
        'less': 'Меньше',
    },
    'creative_weight': {
        'equal': 'Равно',
        'more': 'Больше',
        'less': 'Меньше',
    },
    'creative_height': {
        'equal': 'Равно',
        'more': 'Больше',
        'less': 'Меньше',
    },
    'video_start': {
        'equal': 'Равно',
        'more': 'Больше',
        'less': 'Меньше',
    },
    'video_start_on_mute': {
        'equal': 'Равно',
        'more': 'Больше',
        'less': 'Меньше',
    },
    'video_q25': {
        'equal': 'Равно',
        'more': 'Больше',
        'less': 'Меньше',
    },
    'video_q50': {
        'equal': 'Равно',
        'more': 'Больше',
        'less': 'Меньше',
    },
    'video_q75': {
        'equal': 'Равно',
        'more': 'Больше',
        'less': 'Меньше',
    },
    'video_complete': {
        'equal': 'Равно',
        'more': 'Больше',
        'less': 'Меньше',
    },
    'video_skip': {
        'equal': 'Равно',
        'more': 'Больше',
        'less': 'Меньше',
    },
    'video_close': {
        'equal': 'Равно',
        'more': 'Больше',
        'less': 'Меньше',
    },
}

export const metricsReportRowConditionValue = {
    //метрики целевых значений
    'eventitem_': '',
    'TargetEcomAmount': '',
    'TargetEcomQuantity': '',
    'SessionCount': '',
    'TargetEventValue': '',
    'TargetEventCount': '',
    //Метрики медийные
    'impressions': '',
    'clicks': '',
    'reach': '',
    'frequency': '',
    'givt': '',
    'behavior_fraud_impressions': '',
    'hidden_fraud_impressions': '',
    'visible_impressions': '',
    'avg_visible_impressions_duration': '',
    'median_visible_impressions_duration': '',
    'part_of_screen': '',
    'part_of_visible_screen': '',
    'visible_part': '',
    'creative_weight': '',
    'creative_height': '',
    'video_start': '',
    'video_start_on_mute': '',
    'video_q25': '',
    'video_q50': '',
    'video_q75': '',
    'video_complete': '',
    'video_skip': '',
    'video_close': '',
}

export const metricsReportRowConditionValueTypes = {
    //метрики целевых значений
    'eventitem_': '',
    'TargetEcomAmount': '',
    'TargetEcomQuantity': '',
    'SessionCount': '',
    'TargetEventValue': '',
    'TargetEventCount': '',
    //Метрики медийные
    'impressions': '',
    'clicks': '',
    'reach': '',
    'frequency': '',
    'givt': '',
    'behavior_fraud_impressions': '',
    'hidden_fraud_impressions': '',
    'visible_impressions': '',
    'avg_visible_impressions_duration': '',
    'median_visible_impressions_duration': '',
    'part_of_screen': 'percent',
    'part_of_visible_screen': 'percent',
    'visible_part': '',
    'creative_weight': '',
    'creative_height': '',
    'video_start': '',
    'video_start_on_mute': '',
    'video_q25': '',
    'video_q50': '',
    'video_q75': '',
    'video_complete': '',
    'video_skip': '',
    'video_close': '',
}

export const ListMediaAgg = [
    'EventDate',
    'MediaSource',
    'MediaCampaign',
    'MediaCreative',
    'Domain',
    'SSP',
    'custommedia_',
    'Country',
    'City'
]

export const ListMediaMetrics = [
    'impressions',  // гуд
    'clicks',       // гуд
    'reach',        // гуд
    'frequency',    // гуд
    'givt',         // гуд
    'behavior_fraud_impressions',   // на 12555 нулевые
    'hidden_fraud_impressions',     // на 12555 нулевые
    'visible_impressions',          // на 12555 нулевые
    'avg_visible_impressions_duration', // на 12555 нулевые
    'median_visible_impressions_duration',  // на 12555 нулевые
    'part_of_screen',                       // на 12555 нулевые
    'part_of_visible_screen',               // на 12555 нулевые
    'visible_part',                         // на 12555 нулевые
    'creative_weight',                      // на 12555 нулевые
    'creative_height',                      // на 12555 нулевые
    'video_start',          // гуд
    'video_start_on_mute',  // на 12555 нулевые
    'video_q25',            // гуд
    'video_q50',            // гуд
    'video_q75',            // гуд
    'video_complete',       // гуд
    'video_skip',           // 21137 вышло, но проверить хз где
    'video_close'           // на 12555 нулевые
]

// "impressions",
// "clicks",
// "reach",
// "frequency",
// "givt",
// "behavior_fraud_impressions",
// "hidden_fraud_impressions",
// "visible_impressions",
// "avg_visible_impressions_duration",
// "median_visible_impressions_duration",
// "part_of_screen",
// "part_of_visible_screen",
// "visible_part",
// "creative_weight",
// "creative_height",
// "video_start",
// "video_start_on_mute",
// "video_q25",
// "video_q50",
// "video_q75",
// "video_complete",
// "video_skip",
// "video_close"

export const ListEventAgg:string[] = [
    "TargetEventDate",
    "TargetYandexMetrikaID",
    "TargetGoogleAnalyticsID",
    "TargetEventDomain",
    "TargetEventPath",
    "TargetEventReferrer",
    // "TargetEventUtmSource",
    // "TargetEventUtmMedium",
    // "TargetEventUtmCampaign",
    // "TargetEventUtmContent",
    "customevent_",
    "TargetEventUserId",
    "TargetEventType",      //ok
    "TargetEventCategory",  //ok
    "TargetEventName",      //ok
    "TargetEcomId",
    "TargetEcomItemsId",
    "TargetEcomItemsName",
    "TargetEcomItemsBrand",
    "TargetEcomItemsVariant",
    "TargetEcomItemsCategory1",
    "TargetEcomItemsCategory2",
    "TargetEcomItemsQuantity",
    "TargetEcomItemsPrice"
]

export const ListEventMetrics:string[] = [
    "eventitem_",
    "TargetEcomAmount",     // на 12555 нулевые
    "TargetEcomQuantity",   // на 12555 нулевые
    // "SessionCount",         //
    "TargetEventValue",     // на 12555 нулевые
    "TargetEventCount"      // этого нет, так как будут кастомные целевые
]
