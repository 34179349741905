import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../../../../redux/hooks";

import {setBreadcrumbs} from "../../../../../redux/slices/breadcrumbs";
import {setSidebar} from "../../../../../redux/slices/sidebar";
import {currentUser, currentUserLang, userId} from "../../../../../redux/slices/user";

import '../style.scss';

import TimeFilter from "../../../../../components/ui/TimeFilter";
import Select from "../../../../../components/ui/Select";
import iconModel from "../../../../../assets/icons/model.svg";
import iconWindow from "../../../../../assets/icons/window.svg";
import iconPeriod from "../../../../../assets/icons/period.svg";
import { ReactComponent as CloseIcon } from '../../../../../assets/icons/icon-close-white.svg'
import {
    getProjectAttributions,
    getProjectGeneral
} from "../../../../../actions/project";
import {getDateAgo} from "../../../../../components/ui/TimeFilter/functions";
import {formatDate, QueueServer, sendSdk} from "../../../../../utils";
import cx from "classnames";
import ShortInfo from "../components/ShortInfo";
import DashboardsChart from "../components/Charts";
import DashboardsTable from "../components/Table";
import {trans} from "../../../../../_locales";
import {onLoadingSelectDate} from "../functions";
import {Groups, Models, Windows} from "../../../../../data";
import {getSelectChangesTitle, onChangeGeneralSelect} from "../../../../../components/ui/Select/functions";
import {
    dashboardsSettings,
    setSetting, setSettingEventTarget,
    setSettingModel, setSettingScale,
    setSettingWindow,
    clearFilter
} from "../../../../../redux/slices/dashboards";
import {clearInstructions} from "../../../../../redux/slices/instructions";
import iconTarget from "../../../../../assets/icons/target-icon.svg";
import {closeModal, ModalName, openModal} from "../../../../../redux/slices/modals";
import IconFilterWhite from "../../../../../assets/icons/icon-filter-white.svg";
import IconFilter from "../../../../../assets/icons/icon-filter-blue.svg";
import {Btn} from "../../../../../ui";
import {getEvents} from "../../../../../actions/events";
import {eventsData, ICurrentEvents} from "../../../../../redux/slices/events";
import {getPages} from "../../../../../components/SideMenu/Role";
import {getEventTitles} from "../../Reports/functions";

const DashboardECommerce = () => {
    const dispatch = useAppDispatch();
    const { projectId } = useParams();
    const reduxUser =  useAppSelector(currentUser);
    const reduxUserId =  useAppSelector(userId);
    const reduxEvents = useAppSelector(eventsData);
    const language = useAppSelector(currentUserLang);
    const settings = useAppSelector(dashboardsSettings);
    const permissions = reduxUser && reduxUser.permission ? reduxUser.permission[`${projectId}`] : undefined;
    const isViewGeneral = permissions && permissions['can_view'] && permissions['can_view'].includes('dashboard_general');
    const isViewEcom = permissions && permissions['can_view'] && permissions['can_view'].includes('dashboard_ecommerce');
    const isViewUserBehavior = permissions && permissions['can_view'] && permissions['can_view'].includes('dashboard_user_behavior');

    const [firstLoading, setFirstLoading] = useState(true);

    useEffect(()=> {
        if(projectId && reduxUserId) {
            sendSdk('page_view', {'event_params': {'user': {'uid': reduxUserId},'project_id': projectId, 'user_id': reduxUserId, 'page': 'ecommerce'}})
        }
    }, [projectId, reduxUserId])

    useEffect(() => {
        const tempBreadcrumbs:any = [
            {
              href: '/projects/',
              title: 'Проект',
            },
            {
              href: `/projects/${projectId}/reports/`,
              title: trans('Project reporting', language)
            },
            {
              href: `#`,
              title: trans('E-Commerce Reports', language),
              dataset: {}
            }
        ]
        if(isViewGeneral || (projectId && (+projectId === 12475))) {
            tempBreadcrumbs[2].dataset['general'] = {
                title: trans('General indicators', language),
                href: `/projects/${projectId}/reports/general`
            }
        }
        if(isViewUserBehavior || (projectId && (+projectId === 12475))) {
            tempBreadcrumbs[2].dataset['user_behavior'] = {
                title: trans('User behavior analysis', language),
                href: `/projects/${projectId}/reports/user_behavior`
            }
        }
        if(projectId && (+projectId === 12484 || +projectId===12483)) {
            tempBreadcrumbs[2].dataset['getintent'] = {
                title: 'GetIntent',
                href: `/projects/${projectId}/reports/getintent`
            }
        }
        dispatch(setBreadcrumbs(tempBreadcrumbs));
        dispatch(clearInstructions());
        dispatch(setSidebar(getPages({
            projectId: Number(projectId),
            access: permissions && permissions['can_view'],
            language,
            active: 'dashboards'
        })))
        dispatch(getProjectGeneral({currentUserId: reduxUser.id,projectId: Number(projectId)}));

        if(!(settings && settings.categorize)) {
            if(projectId == '777') {
                setCategorize('day');
            } else setCategorize('day');
        }
    }, [dispatch, projectId, permissions]);

    useEffect(()=> {
        if(settings && Object.keys(settings).length > 0) {
            if (sessionStorage.getItem("settings")) {
                const sessionSettings = sessionStorage.getItem("settings");
                const parsed = sessionSettings && JSON.parse(sessionSettings);
                const temp = {
                    period: settings.period ? settings.period : parsed.period,
                    model: settings.model ? settings.model : parsed.model,
                    window: settings.window ? settings.window : parsed.window,
                    categorize: settings.categorize ? settings.categorize : parsed.categorize,
                    events: settings.events ? settings.events : parsed.events
                }
                sessionStorage.setItem("settings", JSON.stringify(temp));
            } else sessionStorage.setItem("settings", JSON.stringify(settings));
        } else if(sessionStorage.getItem("settings")) {
            const sessionSettings = sessionStorage.getItem("settings");
            const parsed = sessionSettings && JSON.parse(sessionSettings);
            parsed && dispatch(setSetting(parsed));
        }
    }, [settings])

    const contentClassName = cx('page-content__content', 'page-content__content_scroll', 'page-content__shadow');

    //-------Filter--------
    const [periodStart, setPeriodStart] = useState('');
    const [periodEnd, setPeriodEnd] = useState('');
    const [model, setModel] = useState('mtd');
    const [window, setWindow] = useState(30);
    const [categorize, setCategorize] = useState('day');
    const [eventTarget, setEventTarget] = useState('');
    const [selectDate, setSelectDate] = useState<string>(trans('Select the period', language));
    const onChangeSelectDate = (response:any) => {
        setPeriodStart(response.start);
        setPeriodEnd(response.end);
        setSelectDate(`${response.start} - ${response.end}`);
    }
    const datasetModel = Models(language);
    const datasetWindow = Windows(language);
    const datasetCategorize = Groups(language);
    const [datasetEventTarget, setDatasetEventTarget] = useState<any>( {'all_target_events': trans('All event targets', language)})
    const [filterEventTarget, setFilterEventTarget] = useState<Array<ICurrentEvents>>( [])
    const [eventTargetActive, setEventTargetActive] = useState<Array<string>>( [])
    const onChangeEventTarget = (response:object) => {
        const {title, listActive} = getSelectChangesTitle(response, trans('All event targets', language));
        setEventTarget(title);
        setEventTargetActive(listActive);
    }
    useEffect(()=>{
        if(reduxEvents && Array.isArray(reduxEvents)) {
            const result:Array<ICurrentEvents> = [];
            reduxEvents.map((item:any)=> {
                const temp: ICurrentEvents = {} as ICurrentEvents;
                if(eventTargetActive.includes(item.event_target_name)) {
                    temp.event_name = item.event_name;
                    temp.event_type = item.event_type;
                    temp.event_category = item.event_category;
                    temp.event_target_name = item.event_target_name;
                    temp.event_target_price = item.event_target_price;
                    result.push(temp);
                }
            })
            dispatch(setSettingEventTarget(result));
            setFilterEventTarget(result);
        }
    }, [reduxEvents, eventTarget, eventTargetActive])
    useEffect(() => {
        dispatch(getEvents({projectId, calcAttr: true})).then((r) => {
            if (r.payload && typeof r.payload.length === 'number' && Array.isArray(r.payload)) {
                const temp:any = {'all_target_events': trans('All event targets', language)};
                r.payload.map((item:any)=> (temp as any)[item.event_target_name] = item.event_target_name);
                setDatasetEventTarget(temp);
                const sessionSettings = sessionStorage.getItem("settings");
                const parseSettings = sessionSettings && JSON.parse(sessionSettings);
                if(parseSettings && Array.isArray(parseSettings.events)) {
                    const {title, keys} = getEventTitles(parseSettings.events);
                    setEventTarget(title);
                    setEventTargetActive(keys);
                    setFilterEventTarget(parseSettings.events)
                }
            }
        });
    }, [dispatch, language, projectId]);

    useEffect(() => {
        if(firstLoading) {
            setFirstLoading(false);
            dispatch(getProjectAttributions({currentUserId: reduxUser.id, projectId: Number(projectId)})).then((r)=> {
                if(r.payload) {
                    settings && settings.model ? setModel(settings.model) : setModel(r.payload.attribution_model);
                    settings && settings.window ? setWindow(settings.window) : setWindow(r.payload.attribution_window);
                    settings && settings.categorize ? setCategorize(settings.categorize) : setCategorize('day');
                    if(settings && settings.period) {
                        setPeriodStart(settings.period.start);
                        setPeriodEnd(settings.period.end);
                        setSelectDate(`${settings.period.start} - ${settings.period.end}`);
                    } else onLoadingSelectDate({period: r.payload.attribution_period, setPeriodStart, setPeriodEnd, setValue: setSelectDate});
                }
            })
        }

    }, [projectId, reduxUser.id])
    // ------End Request--------
    // ------Filter--------
    const [filter, setFilter] = useState<Array<any>>([]);
    const [filterActive, setFilterActive] = useState(false);
    const onResetFilter = () => {
        dispatch(clearFilter());
        setFilter([]);
        setFilterActive(false);
    }
    const onSubmitFilter = (response:any) => {
        setFilter(response);
        if(response.length > 0) {
            setFilterActive(true);
            sendSdk('event', {
                'event_type': 'filter',
                'event_category': 'campaign_efficiency',
                'event_name': 'active',
                'event_value': '10',
                'event_params': {
                    'project_id': projectId,
                    'user_id': reduxUserId
                }
            });
        }
        else setFilterActive(false);
        dispatch(closeModal(ModalName.FILTER))
    }
    //-------End Filter--------
    const queue = new QueueServer();

    //Hide header when scroll
    const [lastScroll, setLastScroll] = useState(0);
    const defaultOffset = 100;
    const containHide = (elem:HTMLElement) => elem.classList.contains('hide');

    const handleScroll = (event:any) => {
        const { scrollTop, scrollHeight, offsetHeight } = event.target;
        const header = document.querySelector('header');
        const body = document.querySelector('body');
        const content = document.querySelector('.page-content');
        if(body && header && content && scrollTop > lastScroll && !containHide(header) && scrollTop > defaultOffset && (scrollTop < scrollHeight - offsetHeight - 200)) {
            header.classList.add('hide');
            content.classList.add('stretch');
            body.classList.add('no-scroll');
        } else if(body && header && content && scrollTop < lastScroll && containHide(header)) {
            header.classList.remove('hide');
            content.classList.remove('stretch');
            body.classList.remove('no-scroll');
        }
        setLastScroll(scrollTop);
    }

    return (
        <div className={contentClassName} onScroll={() => null}>
            <div className="dashboard-page-filter">
                <div className="dashboard-page-filter__date">
                    <TimeFilter selectActive={selectDate} onChange={onChangeSelectDate}/>
                </div>
                <div className="dashboard-page-filter__select">
                    <Select
                        selectActive={(datasetModel as any)[model]}
                        icon={iconModel}
                        dataset={datasetModel}
                        onChangeActive={(res: any) => onChangeGeneralSelect(res, (value: any) => {
                            setModel(value);
                            dispatch(setSettingModel(value))
                        })}
                    />
                </div>
                <div className="dashboard-page-filter__select">
                    <Select
                        selectActive={`${trans('Attribution window', language)}: ${window} ${trans('days', language)}`}
                        icon={iconWindow} dataset={datasetWindow} onChangeActive={(res: any) => {
                        onChangeGeneralSelect(res, (value: any) => {
                            const result = Number(value);
                            if (result === 15 || result === 30 || result === 60 || result === 90) {
                                setWindow(result);
                                dispatch(setSettingWindow(result))
                            }
                        });
                    }}/>
                </div>
                <div className="dashboard-page-filter__select">
                    <Select
                        selectActive={`${trans('Scale', language)}: ${(datasetCategorize as any)[categorize]}`}
                        icon={iconPeriod}
                        dataset={datasetCategorize}
                        onChangeActive={(res: any) => onChangeGeneralSelect(res, (value: any) => {
                            setCategorize(value);
                            dispatch(setSettingScale(value))
                        })}/>
                </div>
                <div className="dashboard-page-filter__select">
                    <Select
                        selectActive={(datasetEventTarget as any)[eventTarget] ? (datasetEventTarget as any)[eventTarget] : trans('All event targets', language)}
                        icon={iconTarget} dataset={datasetEventTarget} onChangeActive={onChangeEventTarget}/>
                </div>
                <div className="dashboard-page-filter__auto">
                    <button className={`dashboard-page-filter__btn ${filterActive ? 'active' : ''}`} onClick={() => {
                        projectId && dispatch(openModal({
                            name: ModalName.FILTER,
                            data: {
                                onSubmit: onSubmitFilter,
                                projectId: +projectId,
                                page: 'ecom'
                            }
                        }))
                    }}>
                        <img src={filterActive ? IconFilterWhite : IconFilter} alt=""/>
                        {trans('Filter', language)}
                    </button>
                    {filterActive && <Btn
                        className={'dashboard-page-filter__reset'}
                        isIconBtn={true}
                        size='sm'
                        color='transparent'
                        onClick={onResetFilter}
                        icon={{
                            Component: CloseIcon,
                            width: 16,
                            height: 16
                        }}
                    />}
                </div>
            </div>
            <div className="dashboard-page-summary">
                <div className="dashboard-page-summary__item">
                    <ShortInfo
                        name={'ecom_index_income'}
                        titleDefault={trans('ecom_index_income', language)}
                        projectId={Number(projectId)}
                        periodStart={periodStart}
                        periodEnd={periodEnd}
                        model={model}
                        window={window}
                        filter={filter}
                        targetEvents={filterEventTarget[0]}
                        queue={queue}
                    />
                </div>
                <div className="dashboard-page-summary__item">
                    <ShortInfo
                        name={'ecom_index_purchase'}
                        titleDefault={trans('ecom_index_purchase', language)}
                        projectId={Number(projectId)}
                        periodStart={periodStart}
                        periodEnd={periodEnd}
                        model={model}
                        window={window}
                        filter={filter}
                        targetEvents={filterEventTarget[0]}
                        queue={queue}
                    />
                </div>
                <div className="dashboard-page-summary__item">
                    <ShortInfo
                        name={'ecom_index_avg_check'}
                        titleDefault={trans('ecom_index_avg_check', language)}
                        projectId={Number(projectId)}
                        periodStart={periodStart}
                        periodEnd={periodEnd}
                        model={model}
                        window={window}
                        filter={filter}
                        targetEvents={filterEventTarget[0]}
                        queue={queue}
                    />
                </div>
                <div className="dashboard-page-summary__item">
                    <ShortInfo
                        name={'ecom_index_uniq_user'}
                        titleDefault={trans('ecom_index_uniq_user', language)}
                        projectId={Number(projectId)}
                        periodStart={periodStart}
                        periodEnd={periodEnd}
                        model={model}
                        window={window}
                        filter={filter}
                        targetEvents={filterEventTarget[0]}
                        queue={queue}
                    />
                </div>
                <div className="dashboard-page-summary__item">
                    <ShortInfo
                        name={'ecom_index_cr_user_to_purchase'}
                        titleDefault={trans('ecom_index_cr_user_to_purchase', language)}
                        projectId={Number(projectId)}
                        periodStart={periodStart}
                        periodEnd={periodEnd}
                        model={model}
                        window={window}
                        filter={filter}
                        targetEvents={filterEventTarget[0]}
                        queue={queue}
                    />
                </div>
                <div className="dashboard-page-summary__item">
                    <ShortInfo
                        name={'ecom_index_model_drr'}
                        titleDefault={trans('ecom_index_model_drr', language)}
                        projectId={Number(projectId)}
                        periodStart={periodStart}
                        periodEnd={periodEnd}
                        model={model}
                        window={window}
                        filter={filter}
                        targetEvents={filterEventTarget[0]}
                        queue={queue}
                    />
                </div>
            </div>

            <div className="dashboard-page-block">
                <div className="dashboard-page-block__item">
                    <div className="dashboard-page__diagram">
                        <DashboardsChart
                            name={'ecom_chart_purchase_avg_check'}
                            titleDefault={trans('ecom_chart_purchase_avg_check', language)}
                            projectId={Number(projectId)}
                            periodStart={periodStart}
                            periodEnd={periodEnd}
                            model={model}
                            window={window}
                            type={'BarLinear'}
                            categorize={categorize}
                            filter={filter}
                            targetEvents={filterEventTarget[0]}
                            queue={queue}
                        />
                    </div>
                </div>
                <div className="dashboard-page-block__item">
                    <div className="dashboard-page__diagram">
                        <DashboardsChart
                            name={'ecom_chart_uniq_user'}
                            titleDefault={trans('ecom_chart_uniq_user', language)}
                            projectId={Number(projectId)}
                            periodStart={periodStart}
                            periodEnd={periodEnd}
                            model={model}
                            window={window}
                            categorize={categorize}
                            filter={filter}
                            targetEvents={filterEventTarget[0]}
                            type={'Line'}
                            queue={queue}
                        />
                    </div>
                </div>
            </div>

            <div className="dashboard-page-block size-full">
                <div className="dashboard-page-block__item">
                    <div className="dashboard-page__diagram">
                        <DashboardsChart
                            name={'ecom_chart_income_drr_on_period'}
                            titleDefault={trans('ecom_chart_income_drr_on_period', language)}
                            projectId={Number(projectId)}
                            periodStart={periodStart}
                            periodEnd={periodEnd}
                            model={model}
                            window={window}
                            type={'BarLinear'}
                            categorize={categorize}
                            filter={filter}
                            targetEvents={filterEventTarget[0]}
                            queue={queue}
                        />
                    </div>
                </div>
                <div className="dashboard-page-block__item">
                    <div className="dashboard-page-table">
                        <div className="dashboard-page__table">
                            <DashboardsTable
                                name={'ecom_table_source_chain'}
                                projectId={Number(projectId)}
                                periodStart={periodStart}
                                periodEnd={periodEnd}
                                model={model}
                                window={window}
                                // categorize={categorize}
                                filter={filter}
                                targetEvents={filterEventTarget}
                                queue={queue}
                                hideTotal={true}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="dashboard-page-table">
                <div className="dashboard-page__table">
                    <DashboardsTable
                        name={'ecom_table_model_adsource_purchase'}
                        projectId={Number(projectId)}
                        periodStart={periodStart}
                        periodEnd={periodEnd}
                        model={model}
                        window={window}
                        categorize={categorize}
                        filter={filter}
                        targetEvents={filterEventTarget}
                        queue={queue}
                        hideTotal={true}
                    />
                </div>
            </div>

            <div className="dashboard-page-table">
                <div className="dashboard-page__table">
                    <DashboardsTable
                        name={'ecom_table_model_adsource_item'}
                        projectId={Number(projectId)}
                        periodStart={periodStart}
                        periodEnd={periodEnd}
                        model={model}
                        window={window}
                        categorize={categorize}
                        filter={filter}
                        targetEvents={filterEventTarget}
                        queue={queue}
                        hideTotal={true}
                    />
                </div>
            </div>

            {/*<div className="dashboard-page-block">*/}
            {/*    <div className="dashboard-page-block__item short">*/}
            {/*        <div className="dashboard-page__table">*/}
            {/*            <DashboardsTable*/}
            {/*                name={'ecom_table_item_purchase_popular_consumed'}*/}
            {/*                projectId={Number(projectId)}*/}
            {/*                periodStart={periodStart}*/}
            {/*                periodEnd={periodEnd}*/}
            {/*                categorize={categorize}*/}
            {/*                model={model}*/}
            {/*                window={window}*/}
            {/*                filter={filter}*/}
            {/*                targetEvents={filterEventTarget}*/}
            {/*                queue={queue}*/}
            {/*                hideTotal={true}*/}
            {/*            />*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*    <div className="dashboard-page-block__item">*/}
            {/*        <div className="dashboard-page__table">*/}
            {/*            <DashboardsTable*/}
            {/*                name={'ecom_table_page_popular'}*/}
            {/*                projectId={Number(projectId)}*/}
            {/*                periodStart={periodStart}*/}
            {/*                periodEnd={periodEnd}*/}
            {/*                model={model}*/}
            {/*                window={window}*/}
            {/*                // categorize={categorize}*/}
            {/*                filter={filter}*/}
            {/*                targetEvents={filterEventTarget}*/}
            {/*                queue={queue}*/}
            {/*                hideTotal={true}*/}
            {/*            />*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}
            {/*<div className="dashboard-page-table">*/}
            {/*    <div className="dashboard-page__table">*/}
            {/*        <DashboardsTable*/}
            {/*            name={'ecom_table_media_uplift'}*/}
            {/*            projectId={Number(projectId)}*/}
            {/*            periodStart={periodStart}*/}
            {/*            periodEnd={periodEnd}*/}
            {/*            model={model}*/}
            {/*            window={window}*/}
            {/*            // categorize={categorize}*/}
            {/*            filter={filter}*/}
            {/*            targetEvents={filterEventTarget}*/}
            {/*            queue={queue}*/}
            {/*        />*/}
            {/*    </div>*/}
            {/*</div>*/}
        </div>
    )
}

export default DashboardECommerce;