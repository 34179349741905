export const fieldsSite = {
    'period_dt': 'Период',
    'campaign_nm': 'Название кампании',
    'placement_nm': 'Название креатива'
}
export const fieldsConditionsSite = {
    // 'interaction_dt': {
    //     'contain': 'Содержит',
    //     'not_contain': 'Не содержит',
    //     'equal': 'Равно',
    //     'not_equal': 'Не равно'
    // },
    'campaign_nm': {
        'contain': 'Содержит',
        'not_contain': 'Не содержит',
        'equal': 'Равно',
        'not_equal': 'Не равно'
    },
    'placement_nm': {
        'contain': 'Содержит',
        'not_contain': 'Не содержит',
        'equal': 'Равно',
        'not_equal': 'Не равно'
    }
}
export const fieldsConditionValueSite = {
    // 'interaction_dt': '',
    'campaign_nm': '',
    'placement_nm': ''
}

export const fieldsConditionValueTypesSite = {
    'campaign_nm': 'string',
    'placement_nm': 'string'
}

export const metricsSite = {
    spent_metrics: "Расходы",
    plan_spent_metrics: "Расходы план",
    part_plan_spent: "Расходы выполнение",
    impression_metrics: "Показы",
    plan_impression_metrics: "Показы план",
    part_plan_impression: "Показы выполнение",
    cpm: "CPM",
    plan_cpm: "CPM план",
    part_plan_cpm: "CPM выполнение",
    givt_metrics: "GIVT",
    // fraud_impressions_metrics: "SIVT",
    // fraud_share: "Доля сомнительных показов (GIVT + SIVT)",
    reach_metrics: "Охват",
    plan_reach_metrics: "Охват план",
    part_plan_reach: "Охват выполнение",
    // cpr: "Цена за охват 1К юзеров",
    // plan_cpr: "Цена за охват 1К юзеров план",
    // part_plan_cpr: "Цена за охват 1К выполнение",
    imp_on_uniq: "Частота",
    ctr: "CTR",
    click_metrics: "Клики",
    plan_click_metrics: "Клики план",
    part_plan_click: "Клики выполнение",
    cpc: "CPC",
    plan_cpc: "CPC план",
    part_plan_cpc: "CPC выполнение",
    lead_metrics: "Целевые действия",
    cpa: "Стоимость целевого действия",

    consumed_lead_metrics: "Значимые целевые действия",
    cpa_consumed: "Стоимость значимого целевого действия",

    ecom_add_to_cart_metrics: "Добавлений в корзину",
    ecom_purchases_metrics: "Покупки",
    cr_cart_to_purchase: "Конверсия из добавления в корзину в покупку",

    ecom_add_to_cart_consumed_metrics: "Значимые добавления в корзину",
    ecom_purchases_consumed_metrics: "Значимые покупки",
    ecom_quantity_metrics: "Количество товаров",
    ecom_amount_metrics: "Сумма покупок",

    ecom_quantity_consumed_metrics: "Значимо куплено товаров",
    ecom_amount_consumed_metrics: "Значимая сумма покупок",
    ecom_avg_bill: "Средний чек",
    ecom_drr: "Доля рекламных расходов"
}

export const metricsConditionsSite = {
    'spent_metrics': {
        'equal': 'Равно',
        'more': 'Больше',
        'less': 'Меньше',
    },
    plan_spent_metrics: {
        'equal': 'Равно',
        'more': 'Больше',
        'less': 'Меньше',
    },
    part_plan_spent: {
        'equal': 'Равно',
        'more': 'Больше',
        'less': 'Меньше',
    },
    impression_metrics: {
        'equal': 'Равно',
        'more': 'Больше',
        'less': 'Меньше',
    },
    plan_impression_metrics: {
        'equal': 'Равно',
        'more': 'Больше',
        'less': 'Меньше',
    },
    part_plan_impression: {
        'equal': 'Равно',
        'more': 'Больше',
        'less': 'Меньше',
    },
    cpm: {
        'equal': 'Равно',
        'more': 'Больше',
        'less': 'Меньше',
    },
    plan_cpm: {
        'equal': 'Равно',
        'more': 'Больше',
        'less': 'Меньше',
    },
    part_plan_cpm: {
        'equal': 'Равно',
        'more': 'Больше',
        'less': 'Меньше',
    },
    givt_metrics: {
        'equal': 'Равно',
        'more': 'Больше',
        'less': 'Меньше',
    },
    fraud_impressions_metrics: {
        'equal': 'Равно',
        'more': 'Больше',
        'less': 'Меньше',
    },
    fraud_share: {
        'equal': 'Равно',
        'more': 'Больше',
        'less': 'Меньше',
    },
    reach_metrics: {
        'equal': 'Равно',
        'more': 'Больше',
        'less': 'Меньше',
    },
    plan_reach_metrics: {
        'equal': 'Равно',
        'more': 'Больше',
        'less': 'Меньше',
    },
    part_plan_reach: {
        'equal': 'Равно',
        'more': 'Больше',
        'less': 'Меньше',
    },
    cpr: {
        'equal': 'Равно',
        'more': 'Больше',
        'less': 'Меньше',
    },
    plan_cpr: {
        'equal': 'Равно',
        'more': 'Больше',
        'less': 'Меньше',
    },
    part_plan_cpr: {
        'equal': 'Равно',
        'more': 'Больше',
        'less': 'Меньше',
    },
    imp_on_uniq: {
        'equal': 'Равно',
        'more': 'Больше',
        'less': 'Меньше',
    },
    ctr: {
        'equal': 'Равно',
        'more': 'Больше',
        'less': 'Меньше',
    },
    click_metrics: {
        'equal': 'Равно',
        'more': 'Больше',
        'less': 'Меньше',
    },
    plan_click_metrics: {
        'equal': 'Равно',
        'more': 'Больше',
        'less': 'Меньше',
    },
    part_plan_click: {
        'equal': 'Равно',
        'more': 'Больше',
        'less': 'Меньше',
    },
    cpc: {
        'equal': 'Равно',
        'more': 'Больше',
        'less': 'Меньше',
    },
    plan_cpc: {
        'equal': 'Равно',
        'more': 'Больше',
        'less': 'Меньше',
    },
    part_plan_cpc: {
        'equal': 'Равно',
        'more': 'Больше',
        'less': 'Меньше',
    },
    lead_metrics: {
        'equal': 'Равно',
        'more': 'Больше',
        'less': 'Меньше',
    },
    cpa: {
        'equal': 'Равно',
        'more': 'Больше',
        'less': 'Меньше',
    },
    ecom_add_to_cart_metrics: {
        'equal': 'Равно',
        'more': 'Больше',
        'less': 'Меньше',
    },
    ecom_purchases_metrics: {
        'equal': 'Равно',
        'more': 'Больше',
        'less': 'Меньше',
    },
    cr_cart_to_purchase: {
        'equal': 'Равно',
        'more': 'Больше',
        'less': 'Меньше',
    },
    ecom_quantity_metrics: {
        'equal': 'Равно',
        'more': 'Больше',
        'less': 'Меньше',
    },
    ecom_amount_metrics: {
        'equal': 'Равно',
        'more': 'Больше',
        'less': 'Меньше',
    },
    ecom_avg_bill: {
        'equal': 'Равно',
        'more': 'Больше',
        'less': 'Меньше',
    },
    ecom_drr: {
        'equal': 'Равно',
        'more': 'Больше',
        'less': 'Меньше',
    }
}

export const metricsConditionValueSite = {
    spent_metrics: "",
    plan_spent_metrics: "",
    part_plan_spent: "",
    impression_metrics: "",
    plan_impression_metrics: "",
    part_plan_impression: "",
    cpm: "",
    plan_cpm: "",
    part_plan_cpm: "",
    givt_metrics: "",
    fraud_impressions_metrics: "",
    fraud_share: "",
    reach_metrics: "",
    plan_reach_metrics: "",
    part_plan_reach: "",
    cpr: "",
    plan_cpr: "",
    part_plan_cpr: "",
    imp_on_uniq: "",
    ctr: "",
    click_metrics: "",
    plan_click_metrics: "",
    part_plan_click: "",
    cpc: "",
    plan_cpc: "",
    part_plan_cpc: "",
    lead_metrics: "",
    cpa: "",
    ecom_add_to_cart_metrics: "",
    ecom_purchases_metrics: "",
    cr_cart_to_purchase: "",
    ecom_quantity_metrics: "",
    ecom_amount_metrics: "",
    ecom_avg_bill: "",
    ecom_drr: ""
}

export const metricsConditionValueTypesSite = {
    spent_metrics: "",
    plan_spent_metrics: "",
    part_plan_spent: "percent",
    impression_metrics: "",
    plan_impression_metrics: "",
    part_plan_impression: "percent",
    cpm: "",
    plan_cpm: "",
    part_plan_cpm: "percent",
    givt_metrics: "",
    fraud_impressions_metrics: "",
    fraud_share: "",
    reach_metrics: "",
    plan_reach_metrics: "",
    part_plan_reach: "percent",
    cpr: "",
    plan_cpr: "",
    part_plan_cpr: "percent",
    imp_on_uniq: "",
    ctr: "",
    click_metrics: "",
    plan_click_metrics: "",
    part_plan_click: "percent",
    cpc: "",
    plan_cpc: "",
    part_plan_cpc: "percent",
    lead_metrics: "",
    cpa: "",
    ecom_add_to_cart_metrics: "",
    ecom_purchases_metrics: "",
    cr_cart_to_purchase: "",
    ecom_quantity_metrics: "",
    ecom_amount_metrics: "",
    ecom_avg_bill: "",
    ecom_drr: ""
}